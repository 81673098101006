window._ = require('lodash');

/*------------------------------------------
 Loading Bootstrap
------------------------------------------*/

window.bootstrap = require('bootstrap');

// Uncomment to use BS Tooltips 
// const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
// const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))

 /*------------------------------------------
 Loading jQuery
------------------------------------------*/

// Disabling the jQuery call in favor of the native WP jQuery for better 
// WP plugins compatibility, check webpack.mix.js file for more info

window.$ = window.jQuery = require('jquery');

/*--------------------------------------------------
 Loading theme JS plugins (Slick Carousel Example)
--------------------------------------------------*/

window.waypoint = require('waypoints/lib/jquery.waypoints.min.js')
window.counterup = require('counterup/jquery.counterup.min.js')
require('slick-carousel');
window.AOS = require('aos')
window.WOW = require('wow.js')
require("./countdown")
require("@fortawesome/fontawesome-free")
require("owl.carousel")
require("@fancyapps/fancybox")

/*--------------------------------------------------
 Call (any shared or page specific) JS file
--------------------------------------------------*/

require("./theme-shared")